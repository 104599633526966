import React from 'react'
import { Button, FormGroup, MenuItem, Switch } from '@blueprintjs/core'
import { Column, Row } from '../../flex'
import Translator from '../translator'
import FloorSelection from '../../map/floor_selection'
import PlaceSelection from '../../map/place_selection'
import Feature from '../../../models/feature'
import { LANGUAGES } from '../../../models/language'
import Place from '../../../models/place'
import Floor from '../../../models/floor'
import MetadataEditor from '../metadata_editor'
import JSONEditor from '../json_editor'
import { Select } from '@blueprintjs/select'
import { capitalize } from 'lodash'
import WorkingHours from '../working_hours'
import wheelchair from '../../../assets/icon-wheelchair.png';
import EventEditor from '../event_editor'
import Style from "../../../models/style";
import deepEqual from "deep-equal";

type PathDirection = 'bidirectional' | 'unidirectional' | 'unidirectional-opposite';

const DirectionSelect = Select.ofType<PathDirection>();

const TypeSelect = Select.ofType<string>();

interface Props {
  line: Feature
  places: Array<Place>
  floors: Array<Floor>
  onChange: (feature: Feature) => void
  style: Style
}

interface State {
  type: string
}

export default class EditLine extends React.Component<Props, State> {
  state = {
    type: 'default'
  } as State

  componentDidMount() {
    this.setState({
      type: this.props.line?.properties?.type ?? ''
    })
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    // console.log('did update', this.props.line);
    if (!deepEqual(prevProps.line, this.props.line)) {
      // console.log('line changed, updating state', this.props.line?.properties?.type ?? '')
      this.setState({
        type: this.props.line?.properties?.type ?? ''
      })
    } else {
      // console.log('lines are equal, not updating', prevProps.line, this.props.line)
    }
  }

  getFloor(feature: Feature) {
    const floor = this.props.floors.find(p => p.id === feature.properties.floor_id)
    if (!floor) {
      return new Floor({})
    }
    return floor
  }

  getPlace(feature: Feature) {
    const place = this.props.places.find(p => p.id === feature.properties.place_id)
    if (!place) {
      return new Place({})
    }
    return place
  }

  getIcon() {
    return undefined
    // return <img alt={poiType.title} className="editor-amenity" src={poiType.icon} />
  }

  render() {
    let direction: PathDirection = 'bidirectional';

    if (typeof this.props.line.properties.bidirectional === 'boolean' && this.props.line.properties.bidirectional === false) {
      if (this.props.line.properties.swapDirection) {
        direction = 'unidirectional-opposite'
      } else {
        direction = 'unidirectional'
      }
    }

    return <Column flex={1}>
      <h6 className="bp3-heading edit-heading">
        <div style={{width: 44, float: 'left'}}>
          { this.getIcon() }
        </div>
        <Column flex={1}>
          <div style={{ fontSize: 12, fontWeight: 'bold'}}>Line</div>
          <div style={{ fontSize: 16 }}>{ this.props.line.properties.title }</div>
          <div style={{ fontSize: 10 }}>
            level { this.props.line.properties.level }
          </div>
          <div style={{ fontSize: 10 }}></div>
        </Column>
      </h6>

      <Column flex={1} className="edit-block">
        <PlaceSelection
          simple={false}
          selected={this.getPlace(this.props.line)}
          onSelect={(place) => {
            const line = Object.assign({}, this.props.line)
            line.properties.place_id = place.id
            this.props.onChange(line)
          }}
        />
      </Column>

      <Column flex={1} className="edit-block">
        <FloorSelection
          simple={false}
          selected={this.getFloor(this.props.line)}
          onSelect={(floor) => {
            const line = new Feature(this.props.line.json)
            line.properties.floor_id = floor.id
            line.properties.level = floor.level
            this.props.onChange(line)
          }}
        />
      </Column>

      {/*<Column flex={1} className="edit-block">*/}
      {/*  <FormGroup*/}
      {/*    label="Available"*/}
      {/*    inline={true}>*/}
      {/*      <Switch*/}
      {/*        checked={typeof this.props.line.properties.available === 'undefined' ? true : this.props.line.properties.available}*/}
      {/*        onChange={(e) => {*/}
      {/*          const feature = new Feature(this.props.line.json);*/}
      {/*          feature.properties.available = e.currentTarget.checked;*/}
      {/*          this.props.onChange(feature)*/}
      {/*        }}*/}
      {/*      />*/}
      {/*  </FormGroup>*/}
      {/*</Column>*/}

      {/*<Column flex={1} className="edit-block">*/}
      {/*  <FormGroup*/}
      {/*    label={<Row flex={1} className='ai-c'>*/}
      {/*      Accessible*/}
      {/*      <img src={wheelchair} alt="wheelchair" className='icon-small'/>*/}
      {/*    </Row>}*/}
      {/*    inline={true}>*/}
      {/*      <Switch*/}
      {/*        checked={!this.props.line.properties.narrowPath}*/}
      {/*        onChange={(e) => {*/}
      {/*          const feature = new Feature(this.props.line.json);*/}
      {/*          feature.properties.narrowPath = !e.currentTarget.checked;*/}
      {/*          this.props.onChange(feature)*/}
      {/*        }}*/}
      {/*      />*/}
      {/*  </FormGroup>*/}
      {/*</Column>*/}

      {/*<Column flex={1} className="edit-block">*/}
      {/*  <FormGroup*/}
      {/*    label="Ramp"*/}
      {/*    inline={true}>*/}
      {/*      <Switch*/}
      {/*        checked={typeof this.props.line.properties.ramp === 'undefined' ? false : this.props.line.properties.available}*/}
      {/*        onChange={(e) => {*/}
      {/*          const feature = new Feature(this.props.line.json);*/}
      {/*          feature.properties.ramp = e.currentTarget.checked;*/}
      {/*          this.props.onChange(feature)*/}
      {/*        }}*/}
      {/*      />*/}
      {/*  </FormGroup>*/}
      {/*</Column>*/}

      {/*<Column flex={1} className="edit-block">*/}
      {/*  <FormGroup*/}
      {/*    label="Direction"*/}
      {/*    inline={true}>*/}
      {/*    <DirectionSelect*/}
      {/*      filterable={false}*/}
      {/*      activeItem={direction}*/}
      {/*      items={['bidirectional', 'unidirectional', 'unidirectional-opposite']}*/}
      {/*      itemRenderer={(item, options) => <MenuItem*/}
      {/*        key={item}*/}
      {/*        text={capitalize(item)}*/}
      {/*        onClick={options.handleClick}*/}
      {/*      />}*/}
      {/*      onItemSelect={direction => {*/}
      {/*        const feature = new Feature(this.props.line.json)*/}
      {/*        if (direction === 'bidirectional') {*/}
      {/*          feature.properties.bidirectional = true;*/}
      {/*        } else {*/}
      {/*          if (direction === 'unidirectional') {*/}
      {/*            feature.properties.bidirectional = false;*/}
      {/*            feature.properties.swapDirection = false;*/}
      {/*          } else {*/}
      {/*            feature.properties.bidirectional = false;*/}
      {/*            feature.properties.swapDirection = true;*/}
      {/*          }*/}
      {/*        }*/}
      {/*        this.props.onChange(feature)*/}
      {/*      }}>*/}
      {/*        <Button minimal text={capitalize(direction || 'bidirectional')} rightIcon="chevron-down"/>*/}
      {/*    </DirectionSelect>*/}
      {/*  </FormGroup>*/}
      {/*</Column>*/}

      <Column flex={1} className="edit-block">
        <FormGroup
          label="Type"
          inline={true}>
          <TypeSelect
            items={this.props.style.lineTypes}
            onItemSelect={(type) => {
              const line = new Feature(this.props.line.json);
              line.properties.type = type;
              this.props.onChange(line)
            }}
            itemRenderer={(item, options) => <MenuItem
              key={`menu-item-${item}`}
              text={item}
              onClick={options.handleClick}
            />}>
            <Button
              rightIcon={"chevron-down"}
              text={this.state.type.length > 0 ? this.state.type : 'No type selected'}
            />
          </TypeSelect>
        </FormGroup>
      </Column>

      {/*<Translator*/}
      {/*  area={false}*/}
      {/*  feature={this.props.line}*/}
      {/*  field="title_i18n"*/}
      {/*  fieldName="Title"*/}
      {/*  languages={LANGUAGES}*/}
      {/*  onChange={this.props.onChange}*/}
      {/*/>*/}

      {/*<WorkingHours feature={this.props.line} onChange={(_feature) => {*/}
      {/*  const feature = new Feature(this.props.line.json);*/}
      {/*  feature.properties.workingHours = _feature.properties.workingHours;*/}
      {/*  this.props.onChange(feature);*/}
      {/*}}/>*/}

      {/*<EventEditor*/}
      {/*  feature={this.props.line}*/}
      {/*  onChange={(since: Date | null, till: Date | null) => {*/}
      {/*    const feature = new Feature(this.props.line.json);*/}
      {/*    if (typeof feature.properties.metadata !== 'object') {*/}
      {/*      feature.properties.metadata = {}*/}
      {/*    }*/}

      {/*    if (since) {*/}
      {/*      feature.properties.metadata.dateStart = since.getTime();*/}
      {/*    } else {*/}
      {/*      delete feature.properties.metadata.dateStart;*/}
      {/*    }*/}

      {/*    if (till) {*/}
      {/*      feature.properties.metadata.dateEnd = till.getTime();*/}
      {/*    } else {*/}
      {/*      delete feature.properties.metadata.dateEnd;*/}
      {/*    }*/}

      {/*    this.props.onChange(feature);*/}
      {/*  }}*/}
      {/*/>*/}

      {/*<MetadataEditor*/}
      {/*  feature={this.props.line}*/}
      {/*  languages={LANGUAGES}*/}
      {/*  onChange={this.props.onChange}*/}
      {/*/>*/}

      {/* <NewJSONEditor
        json={this.props.line}
        onChange={(json) => {
          const feature = new Feature(json);
          this.props.onChange(feature)
        }}
      /> */}

      <JSONEditor
        readonly={false}
        label="General Metadata"
        id={this.props.line.properties.id}
        value={this.props.line.properties.metadata || "{}"}
        onChange={(value) => {
          const feature = new Feature(this.props.line.json);
          feature.properties.metadata = value;
          this.props.onChange(feature)
        }}
      />

      <JSONEditor
        readonly={true}
        label="Raw GeoJSON"
        id={`${this.props.line.properties.id}-raw`}
        value={JSON.stringify(this.props.line, null, 2)}
        onChange={(value) => {
          try {
            const data = JSON.parse(value)
            const feature = new Feature(data)
            this.props.onChange(feature)
          } catch (e) {
            console.error('Raw JSON Editor Format ERROR', e)
          }
        }}
      />
    </Column>
  }
}
