import React, {useEffect, useState} from 'react'
import { DrawMode } from '../../types/draw_mode'
import DrawModeItem from './draw_mode_item'
import Toolbar from './toolbar'
import { IconName } from '@blueprintjs/core'
import {AppMode} from "../mode_selector";
import {debug} from "../../common";

type DrawItem = {
  label: string
  mode: DrawMode
  icon: IconName,
  floorplanMode: boolean,
  wayfindingMode: boolean,
}

const DrawItems = [
  { label: 'Select Tool', mode: 'simple_select', icon: 'hand-up', floorplanMode: true, wayfindingMode: true },
  { label: 'Add Point', mode: 'draw_point', icon: 'map-marker', floorplanMode: false, wayfindingMode: true },
  { label: 'Add Line', mode: 'draw_line_string', icon: 'layout-linear', floorplanMode: true, wayfindingMode: false },
  { label: 'Add Path', mode: 'draw_path_line_string', icon: 'layout-linear', floorplanMode: false, wayfindingMode: true },
  { label: 'Add Polygon', mode: 'draw_polygon', icon: 'polygon-filter', floorplanMode: true, wayfindingMode: false },
  { label: 'Add Text', mode: 'draw_text', icon: 'font', floorplanMode: true, wayfindingMode: false },
  { label: 'Measure', mode: 'measure', icon: 'flow-review', floorplanMode: true, wayfindingMode: true },
  { label: 'Visibility', mode: 'visibility', icon: 'eye-open', floorplanMode: true, wayfindingMode: true },
  { label: 'Navigation', mode: 'routing', icon: 'walk', floorplanMode: false, wayfindingMode: true },
  { label: 'Settings', mode: 'settings', icon: 'cog', floorplanMode: true, wayfindingMode: true }
] as DrawItem[]

interface Props {
  appMode: AppMode;
  mode: DrawMode;
  onSelect: (mode: DrawMode) => void;
}

export default function DrawTools({ appMode, mode, onSelect }: Props): React.ReactElement {
  const [ tools, setTools ] = useState<DrawItem[]>([]);

  useEffect(() => {
    if (appMode === 'floorplan') {
      setTools(DrawItems.filter(i => i.floorplanMode))
    } else {
      setTools(DrawItems.filter(i => i.wayfindingMode))
    }
  }, [ appMode ]);

  debug.log('draw tools mode:', mode);

  const cls = appMode === 'floorplan' ? 'draw-tools' : 'draw-tools';

  return <Toolbar className={cls}>
    { tools.map(item =>
      <DrawModeItem
        key={item.mode}
        label={item.label}
        mode={item.mode}
        icon={item.icon}
        onSelect={onSelect}
        current={mode}
      />
    )}
  </Toolbar>
}
