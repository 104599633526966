import Feature from '../../models/feature'
import React, {useEffect, useState} from "react";
import {FormGroup, Icon, Spinner, Tab, Tabs, TagInput} from "@blueprintjs/core";
import {Column} from "../flex";
import {Tooltip2} from "@blueprintjs/popover2";
import {axios} from "../../common";
import Language, {LANGUAGES} from "../../models/language";

interface Props {
  label: string,
  point: Feature,
  field: string,
  onChange: (point: Feature) => void
}

type LocalizedTags = { [lang: string]: string[] };

export const SpellingsEditor = ({point, field, label, onChange}: Props) => {
  const [tags, setTags] = useState<LocalizedTags>(point.properties[field] ?? {});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tab, setTab] = useState('spellings-en');

  useEffect(() => {
    setTags(point.properties[field] ?? [])
  }, [ point, field ]);

  const fetchMisspellings = async () => {
    setIsLoading(true);
    const langs = Object.keys(point.properties.title_i18n);
    const localTags = Object.assign({}, tags);

    for (const lang of langs) {
      const key = point.properties.title_i18n[lang];
      const res = await axios.post('https://api.proximi.fi/ai/generate/misspellings', { key });
      localTags[lang] = (res.data as { id: string, items: string[] }).items;
    }

    setTags(localTags);
    const feature = new Feature(point.json);
    feature.properties[field] = localTags;
    onChange(feature);
    setIsLoading(false);
  }

  const renderTranslation = (language: Language) => {
    return <Tab
      key={`spellings-${language.code}`}
      id={`spellings-${language.code}`}
      title={language.code}
      panel={<Column flex={1}>
        <Tooltip2 content={"Press enter to confirm word"}>
          <TagInput
            values={tags[language.code] || []}
            onChange={(values) => {
              const feature = new Feature(point.json)
              feature.properties[field][language.code] = values
              onChange(feature)
            }}
          />
        </Tooltip2>
      </Column>}
    />
  }

  return <Column flex={1} className="edit-block">
    <FormGroup
      label={label}
      inline={true}>

      { !isLoading && <Icon
          icon={"cloud"}
          style={{cursor: 'pointer', paddingTop: 6, paddingRight: 6}}
          color={"green"}
          onClick={fetchMisspellings}
      /> }

      { isLoading && <div style={{ width: 24, paddingTop: 6 }}><Spinner size={16}/></div> }
    </FormGroup>

    <Tabs selectedTabId={tab} onChange={tab => setTab(tab as string)}>
      { LANGUAGES.map(lang => renderTranslation(lang))}
    </Tabs>
  </Column>
}
