import Axios from 'axios'
import deepEqual from 'deep-equal'
import Amenity from './models/amenity'
import moment from 'moment'

export const isDev = window.location.origin === 'http://localhost:3000';

export const portalURL = (window.location !== window.parent.location)
            ? document.referrer
            : document.location.href;

export const BaseURL = 'https://api.proximi.fi';

export const axios = Axios.create({
  baseURL: BaseURL,
  timeout: 60000
});

export const authorize = (token: string) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export const camelToKebab = (input: string) => input.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
export const kebabToCamel = (input: string) => input.replace(/-([a-z])/g, g => g[1].toUpperCase());

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const kebabize = (data: any) => {
  const result: any = {}
  Object.keys(data).forEach((key: string) => {
    if (typeof data[key] !== 'undefined') {
      result[camelToKebab(key)] = data[key]
    }
  })
  return result
}

export const increaseCount = (object: any, _property: string, defaultValue: number = 0) => {
  let property = _property ? _property : 'Unknown'

  if (!object[property]) {
    object[property] = defaultValue
  }

  object[property]++
}

export const formatDate = (date: Date) => moment(date).format('DD.MM.YYYY')

export const parseDate = (date: string) => {
  return moment(date || new Date(1).toISOString(), 'DD.MM.YYYY').toDate()
}

export const formatDateTime = (date: Date) => moment(date).format('DD.MM.YYYY HH:mm:ss')

export const changes = (obj1: any, obj2: any) => {
  const changes = [] as string[]

  Object.keys(obj1).forEach(key => {
    if (typeof obj1[key] === 'object') {
      if (!deepEqual(obj1[key], obj2[key])) {
        changes.push(key)
      }
    } else {
      if (obj1[key] !== obj2[key]) {
        changes.push(key)
      }
    }
  })

  return changes
}

export const getImageFromBase64 = (encoded: string) => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image()
    img.src = encoded
    img.onload = () => resolve(img as HTMLImageElement)
    img.onerror = (e: any) => resolve(img as HTMLImageElement)
  })
}

export const getAmenityImage = (amenity: Amenity, defaultAmenity?: Amenity) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = amenity.icon;
    img.onload = () => {
      resolve({ amenity, img, success: true})
    }
    img.onerror = (e: any) => {
      console.error('amenity loading error', amenity);
      if (defaultAmenity) {
        const img = new Image()
        img.src = defaultAmenity.icon;
        img.onload = () => {
          resolve({ amenity, img, success: true})
        }
        img.onerror = (e: any) => {
          console.error('default amenity loading failed (fallback)');
          resolve({ amenity, img, success: false})
        }
      } else {
        console.error('default amenity loading failed (fallback)');
        resolve({ amenity, img, success: false})
      }
    }
  })
}

export const secondsToHuman = (seconds: number) => {
  const min = Math.floor(seconds / 60)
  const sec = seconds % 60
  return `${min} min ${sec.toFixed(0)} sec`
}

export const LINE_TYPES = ['LineString', 'MultiLineString']
export const POLYGON_TYPES = ['Polygon', 'MultiPolygon']

const dummyConsole = { log: (attrs: any) => {}, info: () => {}, error: () => {} }

export const debug = isDev ? console as any : dummyConsole

export function convertToBase64(file: File): Promise<string> {
  return new Promise((resolve: any, reject: any) => {
    const reader = new FileReader();
    reader.onloadend = (e: any) => {
      resolve(e.target.result)
    }
    reader.readAsDataURL(file)
  })
}

export function sensitiveEqual(a: string, b: string) {
  return typeof a === 'string' && typeof b === 'string'
      ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
      : a === b;
}

export const hasString = (string: string, minLength: number = 1) => {
  return string.length >= minLength
}

export const hasStrings = (strings: Array<string>, minLength: number = 1) => {
  let valid = true
  for (let i = 0; i < strings.length; i++) {
    if (!hasString(strings[i])) {
      valid = false
      break
    }
  }
  return valid
}

export const ellipsis = (string: string) => {
  if (string.length < 50) {
    return string
  }

  return `${string.substr(0, 50)}...`
}

export function downloadStringAsFile(filename: string, content: string) {
  // Create a Blob from the string
  const blob = new Blob([content], { type: 'text/plain' });

  // Create a temporary <a> element
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob); // Create a URL for the Blob
  a.download = filename; // Set the filename for the download

  // Append the element to the document and trigger the download
  document.body.appendChild(a);
  a.click();

  // Cleanup
  document.body.removeChild(a);
  URL.revokeObjectURL(a.href);
}
