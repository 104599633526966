import React from 'react'
import {FormGroup, MenuItem, Button} from '@blueprintjs/core'
import {Select} from '@blueprintjs/select'
import { Column } from '../../flex'
import FloorSelection from '../../map/floor_selection'
import PlaceSelection from '../../map/place_selection'
import Feature from '../../../models/feature'
import Place from '../../../models/place'
import Floor from '../../../models/floor'
import JSONEditor from '../json_editor'
import { Priority } from '../../../types/priority'
import Style from "../../../models/style";
import deepEqual from "deep-equal";
import {debug} from "../../../common";

const TypeSelect = Select.ofType<string>();

interface Props {
  polygon: Feature
  places: Array<Place>
  floors: Array<Floor>
  style: Style,
  onChange: (feature: Feature) => void
}

interface State {
  type: string
  floor: Floor
  place: Place
}

export default class EditPolygon extends React.Component<Props, State> {
  state = {
    floor: new Floor({}),
    place: new Place({}),
    type: ''
  } as State

  componentDidMount() {
    this.setState({
      place: this.getPlace(this.props.polygon),
      floor: this.getFloor(this.props.polygon),
      type: this.props.polygon?.properties?.type ?? ''
    })
  }

  componentDidUpdate(prevProps: Props) {
    debug.log('did update', this.props.polygon);
    if (!deepEqual(prevProps.polygon, this.props.polygon)) {
      debug.log('polygon changed, updating state')
      this.setState({
        place: this.getPlace(this.props.polygon),
        floor: this.getFloor(this.props.polygon),
        type: this.props.polygon?.properties?.type ?? ''
      })
    } else {
      debug.log('polygons are equal, not updating', prevProps.polygon, this.props.polygon)
    }

  }

  getFloor(feature: Feature) {
    const floor = this.props.floors.find(p => p.id === feature.properties.floor_id)
    if (!floor) {
      return new Floor({})
    }
    return floor
  }

  getPlace(feature: Feature) {
    const place = this.props.places.find(p => p.id === feature.properties.place_id)
    if (!place) {
      return new Place({})
    }
    return place
  }

  priorityRenderer(priority: Priority, options: any) {
    return <MenuItem
      key={`priority-${priority.id}`}
      text={priority.title}
      onClick={options.handleClick}
    />
  }

  onTypeChange = (type: string) => {

  }

  render() {
    return <Column flex={1}>
      <h6 className="bp3-heading edit-heading">
        <div style={{width: 44, float: 'left'}}>
        </div>
        <Column flex={1}>
          <div style={{ fontSize: 12, fontWeight: 'bold'}}>POLYGON</div>
          <div style={{ fontSize: 12 }}>{ this.props.polygon.id }</div>
          <div style={{ fontSize: 10 }}>
            level { this.props.polygon.properties.level }
          </div>
          <div style={{ fontSize: 10 }}></div>
        </Column>
      </h6>

      <Column flex={1} className="edit-block">
        <PlaceSelection
          simple={false}
          selected={this.state.place}
          onSelect={(place) => {
            const line = Object.assign({}, this.props.polygon)
            line.properties.place_id = place.id
            this.props.onChange(line)
          }}
        />
      </Column>

      <Column flex={1} className="edit-block">
        <FloorSelection
          simple={false}
          selected={this.getFloor(this.props.polygon)}
          onSelect={(floor) => {
            const line = Object.assign({}, this.props.polygon)
            line.properties.floor_id = floor.id
            line.properties.level = floor.level
            this.props.onChange(line)
          }}
        />
      </Column>

      {/*<div className="edit-block">*/}
      {/*  <FormGroup label="Routable" inline={true}>*/}
      {/*    <Switch*/}
      {/*      checked={this.props.polygon.properties.routable || false}*/}
      {/*      onChange={(evt) => {*/}
      {/*        const polygon = Object.assign({}, this.props.polygon)*/}
      {/*        polygon.properties.routable = typeof polygon.properties.routable === 'boolean' ? !polygon.properties.routable : true*/}
      {/*        if (!polygon.properties.segment && !polygon.properties.routable) {*/}
      {/*          // polygon must be either segment or routable*/}
      {/*          polygon.properties.segment = true*/}
      {/*          polygon.properties.room = false*/}
      {/*        }*/}
      {/*        this.props.onChange(polygon)*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </FormGroup>*/}
      {/*</div>*/}

      {/*<div className="edit-block">*/}
      {/*  <FormGroup label="Segment" inline={true}>*/}
      {/*    <Switch*/}
      {/*      checked={this.props.polygon.properties.segment || false}*/}
      {/*      onChange={(evt) => {*/}
      {/*        const polygon = new Feature(this.props.polygon.json)*/}
      {/*        polygon.properties.segment = typeof polygon.properties.segment === 'boolean' ? !polygon.properties.segment : true*/}
      {/*        if (!polygon.properties.segment && !polygon.properties.routable) {*/}
      {/*          // polygon must be either segment or routable*/}
      {/*          polygon.properties.routable = true*/}
      {/*          polygon.properties.room = false*/}
      {/*        }*/}
      {/*        this.props.onChange(polygon)*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </FormGroup>*/}
      {/*</div>*/}

      {/*<div className="edit-block">*/}
      {/*  <FormGroup label="Room" inline={true}>*/}
      {/*    <Switch*/}
      {/*      checked={this.props.polygon.properties.room || false}*/}
      {/*      onChange={(evt) => {*/}
      {/*        const polygon = new Feature(this.props.polygon.json)*/}
      {/*        polygon.properties.segment = false;*/}
      {/*        polygon.properties.routable = false;*/}
      {/*        polygon.properties.room = typeof polygon.properties.room === 'boolean' ? !polygon.properties.segment : true*/}
      {/*        this.props.onChange(polygon)*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </FormGroup>*/}
      {/*</div>*/}

      {/*{ this.props.polygon.isRoom && <React.Fragment>*/}
      {/*  <ColorEditor*/}
      {/*    property="background-color"*/}
      {/*    propertyType="paint"*/}
      {/*    label="Background Color"*/}
      {/*    description=""*/}
      {/*    defaults="#000000"*/}
      {/*    requirement="Disabled by background-pattern."*/}
      {/*    value={this.props.polygon.properties.color}*/}
      {/*    setValue={(evt: any, type: any, color: any) => {*/}
      {/*      const polygon = new Feature(this.props.polygon.json)*/}
      {/*      polygon.properties.color = color*/}
      {/*      this.props.onChange(polygon)*/}
      {/*    }}*/}
      {/*  />*/}

      {/*  <Column flex={1} className="edit-block">*/}
      {/*    <FormGroup label="Base (m)" inline={true}>*/}
      {/*      <NumericInput*/}
      {/*        buttonPosition="none"*/}
      {/*        value={this.props.polygon.properties.base}*/}
      {/*        onValueChange={(value: number) => {*/}
      {/*          const polygon = new Feature(this.props.polygon.json)*/}
      {/*          polygon.properties.base = value*/}
      {/*          this.props.onChange(polygon)*/}
      {/*        }}/>*/}
      {/*    </FormGroup>*/}
      {/*  </Column>*/}

      {/*  <Column flex={1} className="edit-block">*/}
      {/*    <FormGroup label="Height (m)" inline={true}>*/}
      {/*      <NumericInput*/}
      {/*        buttonPosition="none"*/}
      {/*        value={this.props.polygon.properties.height}*/}
      {/*        onValueChange={(value: number) => {*/}
      {/*          const polygon = new Feature(this.props.polygon.json)*/}
      {/*          polygon.properties.height = value*/}
      {/*          this.props.onChange(polygon)*/}
      {/*        }}/>*/}
      {/*    </FormGroup>*/}
      {/*  </Column>*/}
      {/*</React.Fragment>}*/}

      <Column flex={1} className="edit-block">
        <FormGroup
          label="Type"
          inline={true}>
          <TypeSelect
            items={this.props.style.polygonTypes}
            onItemSelect={(type) => {
              const feature = new Feature(this.props.polygon.json);
              feature.properties.type = type;
              this.props.onChange(feature)
            }}
            itemRenderer={(item, options) => <MenuItem
              key={`menu-item-${item}`}
              text={item}
              onClick={options.handleClick}
            />}>
            <Button
              rightIcon={"chevron-down"}
              text={this.props.polygon.properties.type.length > 0 ? this.props.polygon.properties.type : 'No type selected'}
            />
          </TypeSelect>
        </FormGroup>
      </Column>

      {/*<Translator*/}
      {/*  area={false}*/}
      {/*  feature={this.props.polygon}*/}
      {/*  field="title_i18n"*/}
      {/*  fieldName="Title"*/}
      {/*  languages={LANGUAGES}*/}
      {/*  onChange={this.props.onChange}*/}
      {/*/>*/}

      {/*<Column flex={1} className="edit-block">*/}
      {/*  <FormGroup*/}
      {/*    label="Priority"*/}
      {/*    inline={true}>*/}
      {/*    <PrioritySelect*/}
      {/*      items={Priorities}*/}
      {/*      itemRenderer={this.priorityRenderer}*/}
      {/*      activeItem={this.props.polygon.properties.priority || Priorities[0].id}*/}
      {/*      onItemSelect={(priority) => {*/}
      {/*        const point = new Feature(this.props.polygon.json)*/}
      {/*        point.properties.priority = priority.id*/}
      {/*        this.props.onChange(point)*/}
      {/*      }}>*/}
      {/*      <Button text={this.props.polygon.properties.priority ? (Priorities.find(p => p.id === this.props.polygon.properties.priority) as Priority).title : Priorities[0].title}*/}
      {/*              minimal={true}*/}
      {/*              style={{margin: 0}}*/}
      {/*              rightIcon="double-caret-vertical"/>*/}
      {/*    </PrioritySelect>*/}
      {/*  </FormGroup>*/}
      {/*</Column>*/}

      <JSONEditor
        readonly={false}
        label="General Metadata"
        id={this.props.polygon.properties.id}
        value={this.props.polygon.properties.metadata}
        onChange={(value) => {
          const feature = new Feature(this.props.polygon.json)
          feature.properties.metadata = value
          this.props.onChange(feature)
        }}
      />

      {/*<MetadataEditor*/}
      {/*  feature={this.props.polygon}*/}
      {/*  languages={LANGUAGES}*/}
      {/*  onChange={this.props.onChange}*/}
      {/*/>*/}
    </Column>
  }
}
