import React, { Component } from 'react'
import { Popup } from 'react-mapbox-gl'
import { Button, ButtonGroup, Tooltip, PopoverInteractionKind } from '@blueprintjs/core'
import Feature from '../../models/feature'
import {debug} from "../../common";

interface Props {
  line: Feature
  onDelete: (feature: Feature) => void
}

export default class LinePopup extends Component<Props> {
  render() {
    debug.log('line popup', this.props.line);
    const flats = this.props.line.geometry.coordinates.flat(50)
    return <Popup
      className="map-popup"
      coordinates={[ flats[0], flats[1] ]}
      offset={[24, -24]}>
      <ButtonGroup>
        <Tooltip interactionKind={PopoverInteractionKind.HOVER} intent="danger" content="Delete Feature">
          <Button onClick={() => {this.props.onDelete(this.props.line)}} icon="trash" intent="danger"/>
        </Tooltip>
      </ButtonGroup>
    </Popup>
  }
}
