import {Button} from "@blueprintjs/core";
import {useCallback} from "react";

import floorplanActive from '../assets/floorplan_active.png';
import floorplanPassive from '../assets/floorplan_passive.png';
import wayfindingActive from '../assets/wayfinding_active.png';
import wayfindingPassive from '../assets/wayfinding_passive.png';

export type AppMode = 'wayfinding' | 'floorplan';

interface Props {
  mode: AppMode,
  onChange: (mode: AppMode) => void;
}

export function ModeSelector({ mode, onChange }: Props) {
  const onFloorPlan = useCallback(() => {
    onChange('floorplan')
  }, [onChange]);

  const onWayfinding = useCallback(() => {
    onChange('wayfinding')
  }, [onChange]);

  const floorplanIcon = mode === 'floorplan' ? floorplanActive : floorplanPassive;
  const wayfindingIcon = mode === 'wayfinding' ? wayfindingActive : wayfindingPassive;

  return <div id="mode-selector">
    <Button
      text="Wayfinding"
      className="m-r-12"
      onClick={onWayfinding}
      icon={<img src={wayfindingIcon} alt="icon" className="btn-icon"/>}
      intent={mode === 'wayfinding' ? 'primary' : 'none'}
    />

    <Button
      text="Floorplan"
      onClick={onFloorPlan}
      icon={<img src={floorplanIcon} alt="icon" className="btn-icon"/>}
      intent={mode === 'floorplan' ? 'primary' : 'none'}
    />


  </div>
}
