import DataSource from './data_source'
import Feature, { FeatureCollection } from '../models/feature'
import ChangeContainer from '../models/change_container'
import { axios, debug } from '../common'
import deepEqual from 'deep-equal'
import EventBus from '../event_bus';
import geobuf from 'geobuf';
import Pbf from "pbf";
const AllowedFilters = ['exhibition'];

async function fetchFeatures(from: number, size: number) {
  const url = `/v7/geo/features?from=${from}&size=${size}`;
  try {
    const { data, headers } = await axios.get(url);
    return { features: data.features, total: parseInt(headers['record-total'], 10) };
  } catch (error) {
    console.error('Error fetching features:', error);
    throw error;
  }
}

export default class GeoJSONSource extends DataSource {
  changes: ChangeContainer[] = [];
  dirty: Feature[] = [];
  deleted: Feature[] = [];
  metadataFilter: { [id: string]: string | number | boolean } = {};
  includeNonExhibits = true;
  language: string = 'en';
  date: Date | null = null;
  fullData = new FeatureCollection([]);
  mapbox_logo = true;

  constructor() {
    super('main')
  }

  // async fetch() {
  //   const response = await axios.get('/v5/geo/features?size=50000');
  //   this.data = new FeatureCollection(response.data);
  //   this.fullData = new FeatureCollection(response.data);
  //   this.mapLanguage()
  // }

  async pbfFetch() {
    const { data } = await axios.get('http://localhost:3000/features.pbf', {
      responseType: 'arraybuffer' // Ensure response is treated as binary data
    });

    const collection = geobuf.decode(new Pbf(data)) as unknown as FeatureCollection;

    this.data = new FeatureCollection({ features: collection.features });
    this.fullData = new FeatureCollection({ features: collection.features });
    this.mapLanguage()
  }

  async fetch() {
    const items = [] as Feature[];
    let from = 0;
    let size = 250;
    let totalRecords = 0;
    let recordsFetched = 0;

    // Fetch the total number of records for the first time
    const firstResponse = await fetchFeatures(from, size);
    totalRecords = firstResponse.total;
    recordsFetched += firstResponse.features.length;
    items.push(...firstResponse.features);

    // Calculate the number of parallel requests to make
    const numParallelRequests = Math.ceil(totalRecords / size);

    // Define the number of queues
    const numQueues = 8;

    // Calculate the number of requests per queue
    const requestsPerQueue = Math.ceil(numParallelRequests / numQueues);

    // Create an array to hold all queues
    const queues = [];

    // Create and populate the queues with requests
    for (let i = 0; i < numQueues; i++) {
      const queueRequests = [];
      for (let j = 0; j < requestsPerQueue; j++) {
        const pageIndex = i * requestsPerQueue + j;
        if (pageIndex < numParallelRequests) {
          const newFrom = from + pageIndex * size;
          if (pageIndex !== 0) { // Skip the first request
            queueRequests.push(fetchFeatures(newFrom, size));
          }
        }
      }
      queues.push(queueRequests);
    }

    // Execute all queues with limited concurrency
    for (const queue of queues) {
      const results = await Promise.all(queue);
      results.forEach(result => {
        recordsFetched += result.features.length;
        EventBus.notify('loading:features', { from: recordsFetched, total: totalRecords });
        items.push(...result.features);
      });
    }

    this.data = new FeatureCollection({ features: items });
    this.fullData = new FeatureCollection({ features: items });
    this.mapLanguage()
  }

  create(feature: Feature) {
    debug.log('[GEOJSON] creating feature', feature);
    const f = new Feature(feature);
    debug.log('[GEOJSON] created feature', f, JSON.stringify(f));
    this.data.features.push(f);
    this.changes.push(new ChangeContainer('create', f));
    this.dirty.push(f);
    this.notify('feature-created')
  }

  update(feature: Feature) {
    debug.log('[GEOJSON] updating feature', feature);
    this.mapFeatureLanguage(feature, this.language);

    let idx = this.data.features.findIndex(f => f.id === feature.id || f.properties.id === feature.properties.id);
    let f = null;
    if (idx >= 0) {
      if (!deepEqual(this.data.features[idx], feature, { strict: true })) {
        debug.log('features are not equal');
        f = this.data.features[idx];
        feature.update();
        this.data.features.splice(idx, 1, feature);
        const fullDataIdx = this.fullData.features.findIndex(f => f.id === feature.id);
        if (fullDataIdx >= 0) {
          this.fullData.features.splice(fullDataIdx, 1, feature);
        }
        this.changes.push(new ChangeContainer('update', feature, f));
        debug.log('feature change generated');
      } else {
        debug.log('updated feature is equal to previous version', f, feature, feature.properties.type, this.data.features[idx]?.properties.type)
      }
    } else {
      debug.log('existing feature not found', feature)
    }

    const dirtyIdx = this.dirty.findIndex(f => f.id === feature.id || f.properties.id === feature.properties.id);
    if (f) {
      if (dirtyIdx >= 0) {
        this.dirty.splice(dirtyIdx, 1, feature);
      } else {
        this.dirty.push(feature);
      }
    }

    debug.log('feature updated', feature,f);
    this.notify('feature-updated')
  }

  delete(feature: Feature) {
    debug.log('[GEOJSON] deleting feature', feature);

    const idx = this.data.features.findIndex(f => f.properties.id === feature.properties.id);

    if (idx >= 0) {
      this.deleted.push(this.data.features[idx]);
      this.data.features.splice(idx, 1);
      this.changes.push(new ChangeContainer('delete', new Feature(feature)));
    }

    const dirtyIdx = this.dirty.findIndex(f => f.properties.id === feature.properties.id);

    if (dirtyIdx >= 0 ) {
      this.dirty.splice(dirtyIdx, 1);
    }

    this.notify('feature-deleted')
  }

  resetDirty() {
    this.dirty = []
    this.changes = []
    this.deleted = []
    this.notify('dirty-reset')
  }

  setDateFilter(date: Date | null) {
    this.date = date;
    this.runFilter();
    // if (date) {
    //   const time = date.getTime();
    //   const filtered = this.fullData.features.filter(f => {
    //     if (typeof f.properties.metadata === 'object' &&
    //         typeof f.properties.metadata.dateStart === 'number' &&
    //         typeof f.properties.metadata.dateEnd === 'number') {
    //       console.log('found feature with date range', f, 'pass', time > f.properties.metadata.dateStart && time < f.properties.metadata.dateEnd);
    //       return time >= f.properties.metadata.dateStart && time <= f.properties.metadata.dateEnd
    //     }

    //     return true
    //   });
    //   const collection = new FeatureCollection({features: filtered});
    //   this.data = collection;
    // } else {
    //   this.data = new FeatureCollection({ features: this.fullData.features })
    // }
    // this.notify('filters-changed');
  }

  setMetadataFilter(values: { [id: string]: string | number | boolean}) {
    this.metadataFilter = values;
    debug.log('metadata filter set', values, 'running filter');
    this.runFilter();
  }

  runFilter() {
    const dateFilter = (f: Feature) => {
      if (this.date) {
        const time = this.date.getTime();
        if (typeof f.properties.metadata === 'object' &&
            typeof f.properties.metadata.dateStart === 'number' &&
            typeof f.properties.metadata.dateEnd === 'number') {
          return time >= f.properties.metadata.dateStart && time <= f.properties.metadata.dateEnd
        }
      }

      return true
    }

    const metadataFilter = (f: Feature) => {
      const results = [] as boolean[];

      if (this.includeNonExhibits) {
        if (typeof f.properties.metadata === 'undefined' || typeof f.properties.metadata['exhibition'] === 'undefined') {
          return true
        }
      }

      if (this.usesMetadataFilter) {
        for (const key in this.metadataFilter) {
          if (typeof f.properties.metadata === 'object' &&
              f.properties.metadata.hasOwnProperty(key) &&
              f.properties.metadata[key] !== 'No Selection') {
            results.push(f.properties.metadata[key] === this.metadataFilter[key]);
          }
        }
      } else {
        debug.log('metadata filter returning non exhibit item');
        return this.includeNonExhibits
      }

      if (this.usesMetadataFilter && results.length === 0) {
        return false
      }

      return results.every(r => r)
    }

    const filtered = this.fullData.features
      .filter(dateFilter)
      .filter(metadataFilter);

    this.data = new FeatureCollection({ features: filtered });
    this.notify('filters-changed');
  }

  mapLanguage() {
    const features = this.data.features.filter(f => typeof f.properties.title_i18n === 'object')
    features.forEach(feature => this.mapFeatureLanguage(feature, this.language))
  }

  mapFeatureLanguage(feature: Feature, language: string) {
    if (typeof feature.properties.title_i18n === 'string') {
      feature.properties.title_i18n = JSON.parse(feature.properties.title_i18n)
    }

    if (typeof feature.properties.title_i18n === 'object') {
      feature.properties.title = feature.properties.title_i18n[language]
    }
  }

  query(query: string) {
    return this.data.features
      .filter(f => f.properties.title && f.properties.title.toLowerCase().match(query.toLowerCase()))
  }

  get(id: string) {
    return this.data.features.find(f => f.id === id) as Feature
  }

  getInternal(id: string) {
    return this.data.features.find(f => f.properties.id === id) as Feature
  }

  getProperties() {
    const values = {} as { [id: string]: (string | number | boolean)[] };
    const skipped = ['id', 'organization_id', 'createdAt', 'updatedAt', 'title'];

    this.data.features.forEach(f => {
      for (let property in f.properties.metadata) {
        if (!AllowedFilters.includes(property)) {
          // console.log('property not allowed', property);
          continue
        }

        if (skipped.includes(property) || property.match(/__level/)) {
          continue;
        }

        if (typeof values[property] === 'undefined') {
          values[property] = [];
        }

        if (typeof f.properties.metadata[property] === 'object') {
          for (let subProperty in f.properties.metadata[property]) {
            let key = `${property}.${subProperty}`;

            if (typeof values[key] === 'undefined') {
              values[key] = [];
            }

            if (!values[key].includes(f.properties.metadata[property][subProperty])) {
              values[key].push(f.properties.metadata[property][subProperty])
            }
          }
        } else {
          debug.log('processing property', property, 'is not object', 'value', f.properties.metadata[property]);
          if (!values[property].includes(f.properties.metadata[property])) {
            values[property].push(f.properties.metadata[property])
          }
        }

      }
    });

    return values
  }

  get collection() {
    return {
      type: 'FeatureCollection',
      features: this.data.features.map(f => f.json)
    } as FeatureCollection
  }

  get usesMetadataFilter() {
    return Object.keys(this.metadataFilter).length > 0
  }
}
