import React from 'react'
import { Button, Card, ControlGroup, FormGroup, InputGroup, Menu, MenuItem, TextArea, Collapse, Checkbox } from '@blueprintjs/core'
import { Column } from '../components/flex'
import Edit from './sidebar/edit'
import { MapViewOptions } from './map'
import Amenity from '../models/amenity'
import AmenityCategory from '../models/amenity_category'
import Floor from '../models/floor'
import Place from '../models/place'
import Feature from '../models/feature'
import Language, {LANGUAGES} from '../models/language'
import Style from '../models/style'
import { DrawMode } from '../types/draw_mode'
import Amenities from '../components/wizards/amenities'
import deepEqual from 'deep-equal'
import RoutingView from './sidebar/routing'
import { RouteOptions } from '../sources/routing_source'
import {AppMode} from "./mode_selector";
import {debug} from "../common";

interface Props {
  amenities: Array<Amenity>
  amenityCategories: Array<AmenityCategory>
  floor: Floor
  floors: Array<Floor>
  languages: Array<Language>
  mode: DrawMode
  place: Place
  places: Array<Place>
  style: Style
  routeOptions: RouteOptions
  selected: Array<Feature>
  onChange: (feature: Feature) => void
  onStyleSelect: (style: Style) => void
  onPlaceSelect: (place: Place) => void
  onLogout: () => void
  onMigrate: () => void
  onExport: (languages: Language[]) => void
  options: MapViewOptions
  onAmenityReload: () => void,
  onLayerToggle: (layer: string, value: boolean) => void
  onInspectToggle: (inspect: boolean) => void
  onRouteOptionsChange: (options: RouteOptions) => void
  inspection: Feature[]
  submitGeoJSON: (data: string) => void
  floorSelection: boolean;
  onFloorSelectionToggle: () => void
  appMode: AppMode
}

interface State {
  tab: string,
  amenitiesVisible: boolean,
  mapOptionsVisible: boolean,
  updateKey: number
  paths: boolean
  rasters: boolean
  simpleTiles: boolean
  extrusions: boolean
  inspect: boolean
  geojson: boolean
  data: boolean
  geojsonValue: string
  selectedLanguages: Language[]
}

export default class SideBar extends React.Component<Props, State> {

  constructor (props: Props) {
    super(props)
    this.state = {
      tab: 'edit',
      amenitiesVisible: false,
      mapOptionsVisible: false,
      updateKey: 0,
      paths: true,
      rasters: true,
      extrusions: true,
      inspect: false,
      geojson: false,
      data: false,
      geojsonValue: '',
      simpleTiles: true,
      selectedLanguages: []
    }
    this.handleTabChange = this.handleTabChange.bind(this)
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(prevProps.options, this.props.options)) {
      this.setState({
        updateKey: new Date().getTime()
      })
    }
  }

  handleTabChange (tab: string) {
    this.setState({ tab })
  }

  onExport = () => {
    this.props.onExport(this.state.selectedLanguages);
  }

  render() {
    debug.log('SIDEBAR >', this.props.mode, this.props.appMode);
    return <div className="sidebar">
      { this.props.mode === 'simple_select' && this.props.selected.length === 0 && <p className="sidebar-text">Click on an item on the map to select it</p> }
      { this.props.mode === 'settings' && <React.Fragment>
          <Menu>
            <MenuItem className={"hidden"} icon="chevron-right" text="MANAGE CATEGORIES & AMENITIES" onClick={() => this.setState({ amenitiesVisible: true })} />
            <MenuItem icon={this.state.mapOptionsVisible ? "chevron-down" : "chevron-right"} text="MANAGE MAP PITCH, BOUNDS ETC." onClick={() => this.setState({ mapOptionsVisible: !this.state.mapOptionsVisible })}/>
            <Collapse isOpen={this.state.mapOptionsVisible}>
              <Column flex={1} className="settings" key={`settings-${this.state.updateKey}`}>
                <div className="edit-block">
                  <FormGroup label="Latitude" inline={true}>
                    <InputGroup defaultValue={`${this.props.options.coordinates[1].toFixed(9)}`} readOnly={true}/>
                  </FormGroup>
                </div>
                <div className="edit-block">
                  <FormGroup label="Longitude" inline={true}>
                    <InputGroup defaultValue={`${this.props.options.coordinates[0].toFixed(9)}`} readOnly={true}/>
                  </FormGroup>
                </div>
                <div className="edit-block">
                  <FormGroup label="Zoom" inline={true}>
                    <InputGroup defaultValue={`${this.props.options.zoom.toFixed(2)}`} readOnly={true}/>
                  </FormGroup>
                </div>
                <div className="edit-block">
                  <FormGroup label="Pitch" inline={true}>
                    <InputGroup defaultValue={`${this.props.options.pitch.toFixed(2)}`} readOnly={true}/>
                  </FormGroup>
                </div>
                <div className="edit-block">
                  <FormGroup label="Bearing" inline={true}>
                    <InputGroup defaultValue={`${this.props.options.bearing.toFixed(2)}`} readOnly={true}/>
                  </FormGroup>
                </div>
                <div className="edit-block">
                  <FormGroup label="Bounds" inline={true}>
                    <TextArea
                      defaultValue={`[${this.props.options.bounds
                        .map(c => c.map(d => d.toFixed(9)).join(", "))
                        .map(c => `[${c}]`)
                        .join(",\n")}]`
                      }
                      readOnly={true}
                      style={{height: 100}}
                    />
                  </FormGroup>
                </div>
                <div className="edit-block">
                  <FormGroup label="Migrate data" inline={true}>
                    <Button text="Migrate" onClick={this.props.onMigrate}/>
                  </FormGroup>
                </div>
                <div className="edit-block">
                  <FormGroup label="Floor Selection" inline={true}>
                    <Checkbox
                      checked={this.props.floorSelection}
                      onChange={this.props.onFloorSelectionToggle}
                    />
                  </FormGroup>
                </div>
              </Column>
            </Collapse>
            <MenuItem icon={this.state.inspect ? "chevron-down" : "chevron-right"} text="INSPECT MAP ELEMENTS"
                      onClick={async () => {
                        this.setState({inspect: !this.state.inspect}, () => {
                          this.props.onInspectToggle(this.state.inspect)
                        })
                      }}/>
            <Collapse isOpen={this.state.inspect}>
              {this.props.inspection.length === 0 &&
                <p className="sidebar-text">Hover over the map to inspect features</p>}
              { this.props.inspection.map(feature => <Card style={{ paddingTop: 4, paddingBottom: 4 }}>
                <h5 style={{ marginTop: 0, marginBottom: 0 }}>{ feature.id || feature.properties.id }</h5>
                <p>{ feature.geometry.type }</p>
              </Card>)}
            </Collapse>
            <MenuItem icon={this.state.geojson ? "chevron-down" : "chevron-right"} text="ADD GEOJSON" onClick={() => this.setState({ geojson: !this.state.geojson })}/>
            <Collapse isOpen={this.state.geojson}>
              <TextArea style={{ width: '100%', height: 100 }} placeholder="Paste GeoJSON data here" value={this.state.geojsonValue} onChange={(evt: any) => this.setState({ geojsonValue: evt.currentTarget.value })}/>
              <div style={{ width: '100%', textAlign: 'right' }}>
                <Button style={{ alignSelf: 'flex-end' }} text="Submit" icon="tick" onClick={() => this.props.submitGeoJSON(this.state.geojsonValue)}/>
              </div>
            </Collapse>
            <MenuItem icon={this.state.data ? "chevron-down" : "chevron-right"} text="DATA" onClick={() => this.setState({ data: !this.state.data })}/>
            <Collapse isOpen={this.state.data}>
              <Column flex={1} className="settings" key={`settings-${this.state.updateKey}`}>
                <div className="edit-block">
                  <FormGroup label="CSV POI Export" inline={true}>
                  </FormGroup>
                </div>
                { LANGUAGES.map(language => {
                  return <div className="edit-block" key={`lang-${language.code}`}>
                    <FormGroup label={language.name} inline={true}>
                      <Checkbox
                        checked={typeof this.state.selectedLanguages.find(l => l.code === language.code) !== 'undefined'}
                        onClick={(evt) => {
                          const existing = this.state.selectedLanguages.find(l => l.code === language.code);
                          if (existing) {
                            const langs = this.state.selectedLanguages.filter(l => l.code !== language.code);
                            this.setState({selectedLanguages: langs});
                          } else {
                            const langs = [...this.state.selectedLanguages, language];
                            this.setState({selectedLanguages: langs})
                          }
                        }}
                        />
                    </FormGroup>
                  </div>
                })}
                <Button text="CSV Export" onClick={this.onExport} style={{ marginLeft: 12 }}/>
              </Column>
            </Collapse>
          </Menu>
      </React.Fragment>}
      {['draw_point', 'draw_line_string', 'draw_path_line_string', 'draw_polygon', 'draw_text', 'simple_select'].includes(this.props.mode) &&
        <React.Fragment>
          <Edit
            amenities={this.props.amenities}
            amenityCategories={this.props.amenityCategories}
            selected={this.props.selected}
            languages={this.props.languages}
            places={this.props.places}
            floors={this.props.floors}
            style={this.props.style}
            onChange={this.props.onChange}
          />
        </React.Fragment>
      }

      { this.props.mode === 'visibility' && <React.Fragment>
        <Column flex={1} style={{ paddingLeft: 24, paddingRight: 24}}>
          <Column flex={1} className="edit-block-simple">
            <ControlGroup fill>
              <FormGroup inline label="Clusters">
                <Checkbox checked={this.props.style.cluster} onChange={() => this.props.style.toggleCluster() }/>
              </FormGroup>
            </ControlGroup>
          </Column>
          <Column flex={1} className="edit-block-simple">
            <ControlGroup fill>
              <FormGroup inline label="Paths">
                <Checkbox checked={this.state.paths} onChange={async () => {
                  this.setState({ paths: !this.state.paths }, () => {
                    this.props.onLayerToggle('paths', this.state.paths)
                  })
                }}/>
              </FormGroup>
            </ControlGroup>
          </Column>
          <Column flex={1} className="edit-block-simple">
            <ControlGroup fill>
              <FormGroup inline label="Raster floorplans">
                <Checkbox checked={this.state.rasters} onChange={async () => {
                  this.setState({ rasters: !this.state.rasters }, () => {
                    this.props.onLayerToggle('raster', this.state.rasters)
                  })
                }}/>
              </FormGroup>
            </ControlGroup>
          </Column>
          <Column flex={1} className="edit-block-simple">
            <ControlGroup fill>
              <FormGroup inline label="Custom Tiles">
                <Checkbox checked={this.state.simpleTiles} onChange={async () => {
                  this.setState({ simpleTiles: !this.state.simpleTiles }, () => {
                    this.props.onLayerToggle('simple-tiles-layer', this.state.simpleTiles)
                  })
                }}/>
              </FormGroup>
            </ControlGroup>
          </Column>
          <Column flex={1} className="edit-block-simple">
            <ControlGroup fill>
              <FormGroup inline label="Extruded layers">
                <Checkbox checked={this.state.extrusions} onChange={async () => {
                  this.setState({ extrusions: !this.state.extrusions }, () => {
                    this.props.onLayerToggle('extrusions', this.state.extrusions)
                  })
                }}/>
              </FormGroup>
            </ControlGroup>
          </Column>
          <Column flex={1} className="edit-block-simple">
            <ControlGroup fill>
              <FormGroup inline label="Polygon overlays">
                <Checkbox checked={this.props.style.overlay} onChange={() => this.props.style.toggleOverlay() }/>
              </FormGroup>
            </ControlGroup>
          </Column>
          <Column flex={1} className="edit-block-simple">
            <ControlGroup fill>
              <FormGroup inline label="Show rooms">
                <Checkbox checked={this.props.style.rooms} onChange={() => this.props.style.toggleRooms() }/>
              </FormGroup>
            </ControlGroup>
          </Column>
          <Column flex={1} className="edit-block-simple">
            <ControlGroup fill>
              <FormGroup inline label="Show segments">
                <Checkbox checked={this.props.style.segments} onChange={() => this.props.style.toggleSegments() }/>
              </FormGroup>
            </ControlGroup>
          </Column>
          <Column flex={1} className="edit-block-simple">
            <ControlGroup fill>
              <FormGroup inline label="Show routables">
                <Checkbox checked={this.props.style.routable} onChange={() => this.props.style.toggleRoutable() }/>
              </FormGroup>
            </ControlGroup>
          </Column>
          <Column flex={1} className="edit-block-simple">
            <ControlGroup fill>
              <FormGroup inline label="Show fills">
                <Checkbox checked={this.props.style.fills} onChange={() => this.props.style.toggleFills() }/>
              </FormGroup>
            </ControlGroup>
          </Column>
          <Column flex={1} className="edit-block-simple">
              <ControlGroup fill>
                  <FormGroup inline label="Always show POI text">
                      <Checkbox checked={this.props.style.showText} onChange={() => this.props.style.toggleShowText() }/>
                  </FormGroup>
              </ControlGroup>
          </Column>
        </Column>
      </React.Fragment>}

      { this.props.mode === 'routing' && <RoutingView
        options={this.props.routeOptions}
        onOptionsChange={this.props.onRouteOptionsChange}
      /> }

      <Amenities isOpen={this.state.amenitiesVisible} onClose={() => {
        this.setState({ amenitiesVisible: false })
        this.props.onAmenityReload()
      }}/>
    </div>
  }
}
