import React from 'react'
import EditPoint from './edit/edit_point'
import EditLine from './edit/edit_line'
import EditText from './edit/edit_text'
import EditPolygon from './edit/edit_polygon'
import Amenity from '../../models/amenity'
import Feature from '../../models/feature'
import Language from '../../models/language'
import Place from '../../models/place'
import Floor from '../../models/floor'
import AmenityCategory from '../../models/amenity_category'
import Style from "../../models/style";
import EditPathLine from "./edit/edit_path_line";
import {debug} from "../../common";

interface Props {
  amenities: Array<Amenity>
  amenityCategories: Array<AmenityCategory>
  selected: Array<Feature>
  places: Array<Place>
  floors: Array<Floor>
  languages: Array<Language>
  style: Style,
  onChange: (feature: Feature) => void
}

export default class Edit extends React.Component<Props> {
  isRoutingPoint() {
    return this.props.selected.find(f => f.isRouting)
  }

  isPoint() {
    return this.props.selected.find(f => f.isPoint)
  }

  isLineString() {
    return this.props.selected.find(f => f.isLineString && f.properties.class !== 'path')
  }

  isPathLineString() {
    return typeof this.props.selected.find(f => f.isPathLineString) !== 'undefined'
  }


  isPolygon() {
    return this.props.selected.find(f => f.isPolygon)
  }

  isText() {
    return this.props.selected.find(f => f.isText)
  }

  renderRoutingPoint() {
    const point = this.props.selected.find(f => f.isPoint && f.properties.isRouting)
    if (!point) {
      return
    }

    const isStart = point.properties.amenity === 'route_start'
    return point && <h4 className="inform">
      Routing {isStart ? 'START' : 'FINISH'} point selected
    </h4>
  }

  renderPoint() {
    const point = this.props.selected.find(f => f.isPoint)
    return point ? <EditPoint
      amenities={this.props.amenities}
      amenityCategories={this.props.amenityCategories}
      places={this.props.places}
      floors={this.props.floors}
      onChange={this.props.onChange}
      point={point}
    /> : undefined
  }

  renderLine() {
    const line = this.props.selected.find(f => f.isLineString)
    return line ? <EditLine
      places={this.props.places}
      floors={this.props.floors}
      onChange={this.props.onChange}
      line={line}
      style={this.props.style}
    /> : undefined
  }

  renderPath() {
    debug.log('should render path line edit')
    const line = this.props.selected.find(f => f.isPathLineString);
    debug.log('render path', line);
    return line ? <EditPathLine
      places={this.props.places}
      floors={this.props.floors}
      onChange={this.props.onChange}
      line={line}
    /> : undefined
  }

  renderText() {
    const text = this.props.selected.find(f => f.isText)
    return text ? <EditText
      places={this.props.places}
      floors={this.props.floors}
      onChange={this.props.onChange}
      text={text}
    /> : undefined
  }

  renderPolygon() {
    const polygon = this.props.selected.find(f => f.isPolygon)
    return polygon ? <EditPolygon
      places={this.props.places}
      floors={this.props.floors}
      onChange={this.props.onChange}
      polygon={polygon}
      style={this.props.style}
    /> : undefined
  }

  renderContent() {
    // const f = new Feature(this.props.selected[0]);
    debug.log('EDIT > rendering content', this.isPathLineString(), this.props.selected)
    if (this.isRoutingPoint()) {
      return this.renderRoutingPoint()
    }

    if (this.isText()) {
      return this.renderText()
    }

    if (this.isPoint()) {
      return this.renderPoint()
    }

    if(this.isLineString()) {
      return this.renderLine()
    }

    if(this.isPathLineString()) {
      return this.renderPath()
    }

    if (this.isPolygon()) {
      return this.renderPolygon()
    }

    return null
  }

  render() {
    return <div className="sidebar-edit">
      { this.renderContent() }
    </div>
  }
}
