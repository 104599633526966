export const FONTS = [
  "Amiri Bold",
  "Amiri Bold Slanted",
  "Amiri Regular",
  "Amiri Slanted",
  "Futura Light Light",
  "Futura Light Light,GE SS Two Light",
  "Futura Medium Medium",
  "Futura Medium Medium,GE SS Two Medium",
  "Klokantech Noto Sans Bold",
  "Klokantech Noto Sans CJK Bold",
  "Klokantech Noto Sans CJK Regular",
  "Klokantech Noto Sans Italic",
  "Klokantech Noto Sans Regular",
  "Noto Sans Bold",
  "Noto Sans Bold Italic",
  "Noto Sans Italic",
  "Noto Sans Regular",
  "Open Sans Bold",
  "Open Sans Italic",
  "Open Sans Regular",
  "Open Sans Semibold",
  "Open Sans Semibold Italic",
  "Quicksand Bold",
  "Quicksand Bold,Noto Sans Arabic Bold",
  "Quicksand Regular",
  "Quicksand Regular,Noto Sans Arabic Regular"
]

export const DEFAULT_FONT = "Klokantech Noto Sans Regular"
export const METADATA_POLYGON_EDITING = 'proximiio:polygon-editing'
