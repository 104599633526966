import Feature from '../../models/feature'
import React, {useEffect, useState} from "react";
import {FormGroup, TagInput} from "@blueprintjs/core";
import {Column} from "../flex";
import {Tooltip2} from "@blueprintjs/popover2";
import Row from "../flex/row";

interface Props {
  label: string,
  point: Feature,
  field: string,
  onChange: (point: Feature) => void
}

export const TagEditor = ({point, field, label, onChange}: Props) => {
  const [tags, setTags] = useState<string[]>(point.properties[field] ?? []);

  useEffect(() => {
    setTags(point.properties[field] ?? [])
  }, [ point, field ]);

  return <Column flex={1} className="edit-block">
    <FormGroup
      label={<Row flex={1}>
        <div style={{flex: 1}}>{label}</div>
    </Row>}
      inline={true}>
      <Tooltip2 content={"Press enter to confirm word"}>
        <TagInput
          values={tags}
          onChange={(values) => {
            const feature = new Feature(point.json)
            feature.properties[field] = values
            onChange(feature)
          }}
        />
      </Tooltip2>
    </FormGroup>
  </Column>
}
